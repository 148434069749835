@import "~antd/dist/antd.less";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), url("../font/Mulish-Regular.woff") format("woff"),
    url("../font/Mulish-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Mulish Bold";
  src: local("Mulish-Bold"), url("../font/Mulish-Bold.woff") format("woff"),
    url("../font/Mulish-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Paralucent";
  src: local("Paralucent-DemiBold"),
    url("../font/Paralucent-DemiBold.woff") format("woff"),
    url("../font/Paralucent-DemiBold.ttf") format("ttf");
}

@font-face {
  font-family: "Paralucent DemiBold";
  src: local("Paralucent-DemiBold"),
    url("../font/Paralucent-DemiBold.woff") format("woff"),
    url("../font/Paralucent-DemiBold.ttf") format("ttf");
}

* {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  color: #010302;
}

h1,
h2 {
  font-family: "Paralucent", sans-serif;
  font-size: 25px;
  line-height: 25px;
}

button {
  background: none;
  outline: none;
  border: none;
  font-family: "Paralucent", sans-serif !important;
}

body,
p {
  font-family: "Mulish", sans-serif;
}

p {
  font-size: 17px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-menu-item {
  a {
    font-family: "Paralucent DemiBold", sans-serif !important;
  }
}

.ant-menu-submenu-title {
  span {
    font-family: "Paralucent DemiBold", sans-serif !important;
  }
}

#products\$Menu.ant-menu-sub {
  display: flex;
  flex-flow: row wrap;

  width: 800px;
}

#getAnswers\$Menu.ant-menu-sub {
  display: flex;
  flex-flow: row wrap;
  width: 500px;
}

#about\$Menu.ant-menu-sub {
  display: flex;
  flex-flow: row wrap;
  width: 500px;
}

#partnership\$Menu.ant-menu-sub {
  display: flex;
  flex-flow: row wrap;
  width: 500px;
}

.ant-menu-sub.ant-menu-hidden {
  display: none !important;
}

.ant-menu-sub.ant-menu-inline {
  display: block;
  width: auto;
}

.ant-menu-submenu {
  .ant-menu-item {
    a {
      font-family: "Paralucent DemiBold", sans-serif !important;
    }
  }
}

.ant-menu-inline {
  border-right: none;

  .ant-menu-submenu-inline .ant-menu-submenu-title,
  .ant-menu-item {
    padding-left: 0 !important;
  }

  .ant-menu-submenu-inline {
    svg {
      display: none;
    }
    .ant-menu-item {
      padding-left: 15px !important;
    }
  }
}

.ant-rate {
  color: #ffc107 !important;
}

.ant-notification-center {
  left: 35%;
  transform: translateX(-50%);
  right: auto;
  top: auto;
}

@font-family: "Mulish", sans-serif;@pagination-font-family: "Mulish", sans-serif;@primary-color: #fe0000;@warning-color: #f5ae51;@error-color: #e46969;@success-color: #13c5a8;@input-bg: #fff;@item-active-bg: #fe0000;